import React from "react";
import { Layout, theme, Card, Col, Row, Flex } from "antd";
import Mainmenu from "./components/Mainmenu";
import Footer from "./components/Footer";
import { getArrowOffsetToken } from "antd/es/style/placementArrow";
import { maxHeaderSize } from "http";

const { Content } = Layout;

const App: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Content>
        <Row className="backgroundBanner" align="middle">
            <Col xs={{span: 22, offset: 1}} md={{span: 11, offset: 0}} lg={11}>
                <img src="./img/icon_reto.png" style={{borderRadius: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", maxWidth: "100%", float: "right"}}></img>
            </Col>
            <Col xs={0} md={2} lg={2}></Col>
            <Col xs={{span: 22, offset: 2}} md={{span: 11, offset: 0}} lg={11}>
            <Flex align="center" justify="left">
              <div style={{ height: "100%" }}>
                <h1 className="welcomeText">
                  Herzlich Willkommen
                </h1>
              </div>
              </Flex>
            </Col>
        </Row>
        <div className="container">
          <Row gutter={[{ xs: 0, sm: 16, md: 24, lg: 32 }, 24]}>
            <Col xs={24} md={12} lg={12}>
              <Card title="Beruf" bordered={false}>
                Ich bin Informatiker mit Schwerpunkt Applikationsentwicklung.
                Aktuell bilde ich Lernende aus und vermittle ihnen als Coach
                die grundlegenden Prinzipien der Softwareentwicklung.
              </Card>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Card title="Freizeit" bordered={false}>
                Ihn meiner Freizei bin ich leidenschaftlicher Gleitschirmpilot.
                Ich geniesse es, in der Luft zu sein und die Welt von oben zu betrachten,
                ebenfalls bin ich im Vorstand unseres Gleitschirmclubs aktiv.
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default App;
